/* Opening & closing buttons */

.m-panel.m-plugin-backimglayer.collapsed {
    height: 40px;
}

.m-plugin-backimglayer.opened .m-panel-btn.g-cartografia-flecha-derecha,
.m-plugin-backimglayer.opened .m-panel-btn.g-cartografia-flecha-izquierda {
    background-color: white !important;
    color: #71a7d3 !important;
}

/* Title and images container */

.m-control.m-container.m-backimglayer {
    min-width: auto;
}

/* Título */

div#m-backimglayer-titulo {
    background-color: #71a7d3;
    color: white;
    display: block;
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
    text-align: center;
    width: auto;
}

/* Images container */

.m-panel.m-plugin-backimglayer.opened .m-control.m-container.m-backimglayer>div#m-backimglayer-previews>div[id^="m-backimglayer-lyr-"] {
    border: 5px solid #e9e8e8;
    box-sizing: border-box;
    float: left;
    font-size: 0.9em;
    height: 110px;
    position: relative;
    width: 110px;
    cursor: pointer;
    background-color: #e9e8e8;
}

.m-panel.m-plugin-backimglayer.opened .m-control.m-container.m-backimglayer>div#m-backimglayer-previews>#m-backimglayer-lyr-empty {
  background-color: white !important;
}

div[id^="m-backimglayer-lyr-"]>span {
    background-color: white;
    color: rgb(73, 73, 73);
    height: 20px;
    margin-top: 0;
    top: 80px;
    left: 0;
    line-height: 25px;
    opacity: 0.6;
    position: absolute;
    text-align: center;
    width: 100px;
    /* To insert animation: */
    /* height: 0; */
    /* margin-top: 20px; */
    /* transition: all 0.3s linear; */
    /* top: 100px; */
}

/* span transition */
div[id^="m-backimglayer-lyr-"]:hover>span {
    height: 20px;
    margin-top: 0;
    top: 80px;
}

.m-backimglayer .activeBackimglayerDiv {
    border: 5px solid #306c9f !important;
}

/* Bottom positions fix when images row turns into columns */


.m-bottom.m-left>.m-panel.m-plugin-backimglayer.opened>.m-panel-controls {
    display: -webkit-box;
}

.m-bottom.m-left>.m-plugin-backimglayer.opened>.m-panel-btn {
    position: absolute;
    right: -40px;
}

.m-bottom.m-right .m-control.m-container.m-backimglayer {
    padding-right: 40px;
}

.m-areas>div.m-area>div.m-panel.opened.m-plugin-backimglayer {
    background-color: transparent !important;
    box-shadow: none !important;
    order: -9999999;
}

.m-areas>div.m-area>div.m-panel.collapsed>div.m-panel-controls {
    display: none;
}

/* Firefox fix */

@-moz-document url-prefix() {
    .m-bottom.m-right .m-plugin-backimglayer.opened {
        display: flex;
    }
}

/* Mobile fix */

@media only screen and (max-width: 850px) {

    .m-bottom.m-left>.m-plugin-backimglayer.opened>.m-panel-btn {
        position: relative;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.opened {
        z-index: 99999;
    }

    .m-panel.m-plugin-backimglayer.opened>button {
        left: 0;
        top: 0;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-titulo {
        font-size: 11px;
        padding-left: 0;
        padding-right: 0;
        width: 110px;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews {
        max-height: 80vh;
        overflow: scroll;
        width: 110px !important;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews>div {
        height: 110px;
        width: 110px;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews>div>img {
        height: 100px;
        width: 100px;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews>div>span {
        font-size: 1.1em;
        height: 20px;
        line-height: 23px;
        top: 80px;
        width: 100px;
    }


    /* Firefox fix */
    @-moz-document url-prefix() {
        .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.opened {
            padding: 10px 50px 0;
        }

        .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews {
            padding-bottom: 50px;
        }
    }

    /* Chrome fix
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews {
            height: calc(100vh - 100px);
        }
    }*/

}


/* For method change Movil */
.m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.changeStyleResponsive.opened {
    z-index: 99999;
}

.m-panel.changeStyleResponsive.opened>button {
    left: 0;
    top: 0;
}

.m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.changeStyleResponsive.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-titulo {
    font-size: 11px;
    padding-left: 0;
    padding-right: 0;
    width: 110px;
}

.m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.changeStyleResponsive.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews {
    max-height: 80vh;
    overflow: scroll;
    width: 110px !important;
}

.m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.changeStyleResponsive.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews>div {
    height: 110px;
    width: 110px;
}

.m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.changeStyleResponsive.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews>div>img {
    height: 100px;
    width: 100px;
}

.m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.changeStyleResponsive.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews>div>span {
    font-size: 1.1em;
    height: 20px;
    line-height: 23px;
    top: 80px;
    width: 100px;
}


/* Firefox fix */
@-moz-document url-prefix() {
    .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.changeStyleResponsive.opened {
        padding: 10px 50px 0;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.changeStyleResponsive.opened>div.m-panel-controls>div.m-control.m-container.m-backimglayer>div#m-backimglayer-previews {
        padding-bottom: 50px;
    }
}



@media only screen and (max-width: 433px) {

    /* Edge fix */
    @supports (-ms-ime-align:auto) {
        .m-areas>div.m-area>div.m-panel.m-plugin-backimglayer.opened {
            padding: 10px 50px 0;
        }
    }
}


/* Icon font */

@font-face {
    font-family: 'backimglyr';
    src: url('../fonts/backimglyr.eot?cn6g4');
    src: url('../fonts/backimglyr.eot?cn6g4#iefix') format('embedded-opentype'),
        url('../fonts/backimglyr.ttf?cn6g4') format('truetype'),
        url('../fonts/backimglyr.woff?cn6g4') format('woff'),
        url('../fonts/backimglyr.svg?cn6g4#backimglyr') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.m-areas>div.m-area>div.m-panel.m-plugin-backimglayer>div.m-panel-controls>div.m-control>button,
.m-areas>div.m-area>div.m-panel.m-plugin-backimglayer>button.m-panel-btn.backimglyr-simbolo-cuadros,
[class^="backimglyr-"],
[class*=" backimglyr-"] {
    font-family: 'backimglyr' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.backimglyr-simbolo-cuadros:before {
    content: "\e1777";
}
